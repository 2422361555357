/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import SelectEnterprise from "../../../components/SelectEnterprise";
import ChatIcon from "../../../assets/icons/chat.svg";
import DotsIcon from "../../../assets/icons/dots-vertical.svg";
import SendIcon from "../../../assets/icons/send-icon.svg";
import ModalFilter from "../../../components/Modal";
import { useEffect, useState } from "react";
import MessagesAPI from '../../../api/messages/messages';

export default function NewChannel() {
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [useDataChat, setUseDataChat] = useState([])

    async function listChatsTopics() {
        try {
            const response = await MessagesAPI.listChatsTopics()
            if (response) setUseDataChat(response)
        } catch (error) {
            console.error('Erro ao listar chats',error)
        }
    }   

    async function sendMessage() {
        try {
            const response = await MessagesAPI.sendMessage(12,452, 'Oi', )
            console.log('response', response)
        } catch (error) {
            console.error('erro ao enviar mensagem para o usuário de destino', error)
        }
    }
    
    useEffect(() => {
        if (selectedConversation) {
            async function getMessagenInChat() {
                try {  
                    const response = await MessagesAPI.getMessagesChat(selectedConversation.Id, selectedConversation.Title)
                    console.log('response das conversas', response)
                } catch (error) {
                    console.error('Erro ao listar conversas do chat', error)
                }
            }
            getMessagenInChat()
        }

    },[selectedConversation])

    useEffect(() => {
        listChatsTopics()
    },[])

    return (
        <div className="flex flex-col pl-10">
              <div className="flex w-full">
                <div className="w-1/2">
                    <Header
                        title="Mensagens"
                        subTitle="Visualize um histórico de conversa e responda aos usuários."
                        arrowIcon={false}
                    />
                </div>
                <div className="flex flex-col items-end  gap-5 w-1/2">
                    <div>
                        <SelectEnterprise />
                    </div>
                </div>
            </div>

            <div className="flex gap-6">
                <div className="py-4 px-4 rounded-lg bg-white shadow-2xl w-[352.33px]">
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-col gap-4">
                            <Input
                                label=""
                                placeholder="Pesquisar"
                                type="text"
                                icon={true}
                            />
                            <ModalFilter />           
                        </div>
                        <div className="scroll-container p-2 px-4 border-b-2 border-[#E3E7FF] max-h-screen overflow-y-auto">
                        {
                            useDataChat.map(value => {
                                return (
                                    <div className="p-2 px-4 border-b-2 border-[#E3E7FF] cursor-pointer"
                                    onClick={() => {setSelectedConversation(value)}}
                                    >
                                    <div className="flex flex-col gap-1 ">
                                        <div className="flex justify-between">
                                            <div className="flex gap-2 items-center">
                                                <p className="text-[#404040] font-semibold text-base">
                                                    {value.Username}
                                                </p>
                                                <div className="text-[#8A97B1] flex items-center gap-2 p-1 px-2 rounded-[48px] border border-[#E3E7FF)]">
                                                    {value.Title}
                                                </div>
                                            </div>
        
                                            {/* <div className="text-[10px] font-normal leading-normal">
                                                <p>10:43</p>
                                            </div> */}
        
                                        </div>
                                        <div className="flex justify-between text-xs text-[#6D7882] font-normal ">
                                            <div>
                                                <p>
                                                    {value.Message.length > 30 ? value.Message.substring(0, 30) + '...' : value.Message}
                                                </p>                                            
                                            </div>
                                            {/* <div className="flex flex-col justify-center items-center gap-[10px] p-0 px-2 rounded-[50px] bg-[#F28E00] text-[#FCFCFA] ">
                                                <div>
                                                    <p>{value.messagesLength}</p>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                )
                            })
                        }
                    </div>
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center p-4 gap-4 flex-[1_0_0] self-stretch shadow-sm">
                    {selectedConversation ? (
                        <div className="flex flex-col justify-between p-4 gap-4 flex-[1_0_0] self-stretch shadow-sm">
                            <div className="flex justify-between border-b-2 border-[#E3E7FF] text-[#404040]">
                                <div>
                                    <p>{selectedConversation.Username}</p>
                                </div>
                                <div>
                                    <img src={DotsIcon} />
                                </div>
                            </div>
                            <div className="flex gap-4 justify-between items-center bg-[#F4F5F7] p-4">
                                <div className="w-full">
                                    <Input 
                                        label={""} 
                                        placeholder={""} 
                                        type={"text"}                                
                                    />
                                </div>
                                <div>
                                    <button className="bg-[#F28E00] p-2 px-4 rounded-lg">
                                        <img src={SendIcon} onClick={() => sendMessage()}/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col justify-center items-center p-4 gap-4 flex-[1_0_0] self-stretch shadow-sm">
                            <img src={ChatIcon} alt="" />
                            <p className="text-[#404040] font-bold">Boas-vindas</p>
                            <p className="text-[#8A97B1] text-center">
                                Para começar a enviar mensagens, primeiro selecione 
                                uma conversa na lista ao lado.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
