/* eslint-disable @typescript-eslint/no-explicit-any */
import ky from 'ky';
import { ILogin } from './types';
// const API_URL = import.meta.env.VITE_APP_API_URL;

class LoginAPI {
    constructor() {
        
    }
    private async getHeaders(contentType?: string) {
        const headers: Record<string, string> = {
            'Accept': 'application/json',
        };
        if (contentType) {
            headers['Content-Type'] = contentType;
        }
        return headers;
    }

    public async postLogin(email: string, password: string): Promise<ILogin> {
        const headers = await this.getHeaders('application/json');
        try {
            const response = await ky.post(`https://dev.investor.api.vegait.com/api/1/auth/admin`, { json: { email, password }, headers });
            return response.json();
        } catch (error: any) {
            if (error.response) { 
                try {
                    const response = await ky.post(`https://dev.investor.api.vegait.com/api/1/auth/investor`, { json: { email, password }, headers });
                    return response.json();
                } catch (investorError) {
                    console.error('Erro ao realizar login como investidor', investorError);
                    throw investorError;
                }
            }
            console.error('Erro ao realizar login', error);
            throw error;
        }
    }
}

export default new LoginAPI();