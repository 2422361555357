/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid2 } from "@mui/material";
import Header from "../../components/Header";
import SelectEnterprise from "../../components/SelectEnterprise";
import Input from "../../components/Input";
import { Controller, useForm } from "react-hook-form";
import MultiSelect from "../../components/MultiSelect";
import PageSize from "../../components/Table/PageSize";
import Table from "../../components/Table";
import Pagination from "../../components/Table/Pagination";
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useNavigate } from "react-router-dom";
import { SetStateAction, useEffect, useState } from "react";
import { getColumns } from "./columns";
import Button from "../../components/Button";
import DeleteModal from "../../components/DeleteModal";
import { IFiles } from "./types";
import file from "../../api/file/file";

export default function FilesPage() {
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [numberOfPages, setNumberOfPages] = useState<number>(0);
    const [deletingFile, setDeletingFile] = useState<IFiles | null>(null);
    const [selectedValues, setSelectedValues] = useState([]);
    const [useDataFiles, setUseDataFiles] = useState<any>([]);

    const useData = [{name: 'Boleto Vega 08/2024', category: '24/08/2024'},
        {name: 'Boleto Vega 08/2024', category: '24/08/2024'},
    ]

    const dataEdit = [
        { name: 'teste', category: 1 },
    ];

    const data = [
        { name: 'todos', category: 1 },
    ];

    const handleMultiSelectChange = (values: SetStateAction<never[]>) => {
        setSelectedValues(values);
    };

    const newChannelCreateSchema = z.object({
        title: z.any(),
        responsibleUsers: z.any(),
    })
      
    type NewChannelsSchema = z.infer<typeof newChannelCreateSchema>

    const { register, handleSubmit, control, reset, formState: { errors } } = useForm<NewChannelsSchema>({
        resolver: zodResolver(newChannelCreateSchema),
    });

    function handleFilter(data:NewChannelsSchema)  {
      console.log(data)
    }

    function clearFilters() {
        reset();
    }

    const editFile = () => {
        navigate('/arquivos/editar-arquivo', { state: { isEdit: true, data: dataEdit } });
    };

    async function downloadDocumentHandler(userParam: any) {
        if (!userParam.File) {
            console.error('URL do arquivo não está definida.');
            return;
        }
        try {
            const response = await fetch(userParam.File);
            if (!response.ok) {
                throw new Error('Não foi possível baixar o arquivo');
            }
            const blob = await response.blob();
            const downloadUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = userParam.file_url.split('/').pop() || 'download';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Erro ao baixar o arquivo:', error);
        }
    }

    async function listFiles() {
        try {
            const response = await file.listFile()
            if (response) setUseDataFiles(response)
            return response
        } catch (error) {
            console.error('Erro ao listar os arquivos', error)
        }
    }

    async function deleteItem(deletingItem: IFiles) {
        // const response = await file.deleteFile(deleteItem.id)
        console.log('deletingItem', deletingItem)
        // setDeletingFile(null)
        // return response
    }

    useEffect(() => {
        listFiles()
    },[])

    return(
        <Grid2 container spacing={1} mt={5} ml={5} mr={1}>
        <div className="flex w-full">
            <div className="w-1/2">
                <Header
                    title="Arquivos"
                    subTitle="Visualize e gerencie os arquivos disponibilizados aos usuários."
                    arrowIcon={false}
                />
            </div>
            <div className="flex flex-col items-end  gap-5 w-1/2">
                <div>
                    <SelectEnterprise />
                </div>

                <div>
                    <Button
                        title="+ Novo arquivo"
                        color="#fff"
                        backGroundColor="#F28E00"
                        borderRadius={8}
                        onClick={() => navigate('/arquivos/cadastrar-arquivo')}
                    />
                </div>
            </div>
        </div>

        <div className="w-screen shadow-custom rounded-lg p-6">
        <form className="flex flex-col gap-6"  onSubmit={handleSubmit(handleFilter)}>
            <div className="flex gap-6">
                <div className="w-2/4">
                    <Input 
                        label="Nome"
                        placeholder="Digite o título do arquivo"
                        type="text"
                        register={register("title")}
                    />
                </div>
            <div className="w-2/4">
            <Controller
                    name="responsibleUsers"
                    control={control}
                        render={({ field }) => (
                            <MultiSelect
                                label="Status"
                                data={data}
                                onChange={(values: SetStateAction<never[]>) => {
                                    field.onChange(values);
                                    handleMultiSelectChange(values);
                                }}
                                style={{ width: '100%' }}
                                register={field}
                                errors={errors.responsibleUsers}
                                message="Campo obrigatório"
                            />
                        )}
                />
                </div>
            </div>
                <div className="flex justify-end gap-4">
                    <Button
                        title="Limpar"
                        color="#F28E00"
                        backGroundColor="#FCFCFA"
                        borderColor="#E3E7FF"
                        borderRadius={8}
                        onClick={() => clearFilters()}
                        type="reset"
                    />
                    <Button
                        title="Buscar"
                        color="#fff"
                        backGroundColor="#F28E00"
                        borderRadius={8}
                        onClick={() => console.log('cliquei')}
                    />
                </div>
            </form>
        </div>

        <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
            <div className="flex justify-between">
                <div className="text-[#6D7882] font-main">
                    <p><span className="font-bold font-main">{useData.length}</span> itens cadastrados</p>
                </div>
                <div className="flex gap-4 items-center">
                    <PageSize
                            pageSize={pageSize}
                            setPageSize={(size) => {
                            setPageSize(size);
                            setCurrentPage(1);
                        }}
                    />
                    <p className="text-[#6D7882]">Itens por página</p>
                </div>
            </div>

            <Table 
                data={useDataFiles}
                columns={getColumns(
                    editFile, 
                    setDeletingFile,
                    downloadDocumentHandler
                )}
                hidePagination
            />
            <Pagination
                pageCount={numberOfPages}
                currentPage={currentPage - 1}
                setPage={(page) => {
                    setCurrentPage(page + 1); 
                }}
                setPagePrevious={page => setCurrentPage(page + 1)}
            />
        </div>
    {
        deletingFile && (
            <DeleteModal 
                itemName={deletingFile.name}
                confirm={() => deleteItem(deletingFile)}
                cancel={() => setDeletingFile(null)}
            />
        )
    }
        </Grid2>
    )
}