import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <Router>
      <div className="w-screen p-0">
        <AppRoutes />
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;
