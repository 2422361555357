import { useEffect, useState, useMemo } from 'react'
import DataTable from 'react-data-table-component'
// import { defaultTheme } from 'themes/theme'
import * as S from './styles'
import { ITableProps } from './types'
import Pagination from './Pagination'

const defaultStyles = {
  rows: {
    style: {
      width: '100%',
      minHeight: '0px',
      color: '#6D7882',
      fontWeight: 400,
      fontSize: 14,
      fontFamily: 'Poppins, sans-serif',
      padding: '10px 0',
      '&:not(:last-of-type)': {
        borderBottomStyle: 'solid',
        borderBottomWidth: '1.5px',
        // borderBottomColor: defaultTheme.colors.lunarRock
      }
    }
  },
  headCells: {
    style: {
      color: '#404040',
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '5px',
      paddingBottom: '5px',
      fontFamily: 'Poppins, sans-serif',
    }
  },
  headRow: {
    style: {
    //   borderBottom: `1px solid ${defaultTheme.colors.lunarRock}`
    }
  },
  cells: {
    style: {
      fontFamily: 'Poppins, sans-serif',
      paddingLeft: '8px',
      paddingRight: '8px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      minWidth: '0 !important'
    }
  }
}

const alternativeStyles = {
  rows: {
    style: {
      width: '100%',
      minHeight: '0px',
      color: '#6D7882',
      fontWeight: 400,
      fontSize: 14,
      fontFamily: 'Poppins, sans-serif',
      padding: '10px 0',
      '&:not(:last-of-type)': {
        borderBottomStyle: 'solid',
        borderBottomWidth: '1.5px',
        // borderBottomColor: defaultTheme.colors.lunarRock
      }
    }
  },
  headCells: {
    style: {
      fontWeight: 500,
      fontSize: 15,
      color: '#404040',
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '5px',
      paddingBottom: '5px'
    }
  },
  headRow: {
    style: {
    //   borderBottom: `1px solid ${defaultTheme.colors.lunarRock}`
    }
  },
  cells: {
    style: {
      paddingTop: '10px',
      paddingBottom: '10px',
      fontFamily: 'Poppins, sans-serif',
      paddingLeft: '8px',
      paddingRight: '8px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      minWidth: '0 !important'
    }
  }
}

function Table({
  columns,
  data,
  hidePagination,
  perPage,
  emptyMessage,
  useAlternativeStyles = false
}: ITableProps & { useAlternativeStyles?: boolean }) {
  const rowsPage = perPage ? perPage : 10
  const [page, setPage] = useState(0)
  const [numberOfPages, setNumberOfPages] = useState(1)

  useEffect(() => {
    if (data) {
      setNumberOfPages(Math.ceil(data.length / rowsPage))
      setPage(0)
    }
  }, [data, rowsPage])

  const records = useMemo(() => {
    return data ? data.slice(page * rowsPage, page * rowsPage + rowsPage) : []
  }, [data, page, rowsPage])

  const appliedStyles = useAlternativeStyles ? alternativeStyles : defaultStyles

  return (
    <S.Container>
      <DataTable
        columns={columns}
        data={records}
        customStyles={appliedStyles as never}
        paginationPerPage={rowsPage}
        noDataComponent={emptyMessage || 'Nenhum registro encontrado'}
      />
      {!hidePagination && (
        <Pagination
          pageCount={numberOfPages}
          setPage={setPage}
          currentPage={page}
        />
      )}
    </S.Container>
  )
}

export default Table
