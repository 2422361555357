import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ActionsMenu from '../../components/Table/ActionsMenu';
import { IFiles } from './types';

export function getColumns(
    toggleIsEdit?: ((row: IFiles) => void) | null, 
    deleteHotelChain?: ((row: IFiles) => void) | null,     
    onDownload?: ((row: IFiles) => void) | null,
) {
    return [
        {
            name: 'Ícone',
            selector: (row: { Icon: string }) => (
                <img src={row.Icon} alt='Icone' />
            ),
        },
        {
            name: 'Título',
            selector: (row: { Name: string }) => (
                <span>{row.Name}</span>
            ),
        },
        {
            name: 'Status',
            selector: (row: { category: number }) => (
                <FormGroup>
                    {
                        row.category === 1 ? (
                            <FormControlLabel control={<Switch defaultChecked />} label="Ativo " />
                        ) : (
                            <FormControlLabel control={<Switch defaultChecked />} label="Inativo " />
                        )
                    }
                </FormGroup>
            ),
        },
        
        {
            width: '60px',
            cell: (row: IFiles) => (
                <ActionsMenu
                    onEdit={toggleIsEdit ? () => toggleIsEdit(row) : null}
                    onDownload={onDownload ? () => onDownload(row) : null}
                    onDelete={deleteHotelChain ? () => deleteHotelChain(row) : null}
                />
            ),
        },
    ];
}
