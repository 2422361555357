/* eslint-disable @typescript-eslint/no-explicit-any */
import ky from 'ky';
// const API_URL = import.meta.env.VITE_APP_API_URL;
// const AUTH_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VyVHlwZSI6IjIiLCJ1c2VyX2lkIjoiNDUyIiwibmJmIjoxNzI4Mzk2OTg5LCJleHAiOjE3Mjg0ODMzODksImlhdCI6MTcyODM5Njk4OSwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo1MjY4IiwiYXVkIjoibG9jYWxob3N0LWludmVzdG9yLXdlYiJ9.B_y4HVDK4ClGwC_eFZ4LVtF3kIAXfNN9FciSOi6FGPY';

class MessagesAPI {
    constructor() {
        
    }
    private async getHeaders(contentType?: string) {
        const headers: Record<string, string> = {
            'Accept': 'application/json',
        };
    
        const token = localStorage.getItem('token');
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
    
        if (contentType) {
            headers['Content-Type'] = contentType;
        }
    
        return headers;
    }

    public async postNewChannel(ChannelIcon: File | null, name: string, UserIds: string[] ): Promise<unknown> {
        const formData = new FormData();
        formData.append('Title', name);
        formData.append('NetworkUnitId', '1');
        formData.append('Active', 'true');
        UserIds.forEach(id => formData.append('UserIds[]', id));

        if (File && ChannelIcon) {
            formData.append('ChannelIcon', ChannelIcon);
        }

        try {
            const headers = await this.getHeaders();
            const data = await ky.post(`https://dev.investor.api.vegait.com/api/1/chat-topic`, { body: formData, headers });
            console.log('data da requisição', data)
            return data;
        } catch (error) {
            console.error('Erro ao cadastrar novo canal', error);
            throw error;
        }
    }

    public async listNumberOfChannels(): Promise<undefined> {
        const headers = await this.getHeaders('application/json');
        try {
            const response = await ky.get(`https://dev.investor.api.vegait.com/api/1/chat-topic-user/1`, { headers });
            return response.json(); 
        } catch (error) {
            console.error('Erro ao listar números de comunicados', error);
            return undefined
        }
    }

    public async listChannels(): Promise<undefined> {
        const headers = await this.getHeaders('application/json');
        //colocar o id do usuario e o id da unit
        try {
            const response = await ky.get(`https://dev.investor.api.vegait.com/api/1/chat-topic/list-channel/488/1`, { headers });
            return response.json(); 
        } catch (error) {
            console.error('Erro ao listar comunicados', error);
            return undefined; 
        }
    }
    public async getMessagesChat(ChatTopicId: number, Title: string): Promise<undefined> {
        const headers = await this.getHeaders('application/json');
        //colocar o id do usuario e o id da unit
        try {
            const response = await ky.get(`https://dev.investor.api.vegait.com/api/1/chat/${ChatTopicId}/${Title}`, { headers });
            return response.json(); 
        } catch (error) {
            console.error('Erro ao listar conversas', error);
            return undefined; 
        }
    }

    public async getResponsibleUsers(): Promise<undefined> {
        const headers = await this.getHeaders('application/json');
        try {
            const response = await ky.get(`https://dev.investor.api.vegait.com/api/1/user/admin/units/1`, { headers });
            return response.json(); 
        } catch (error) {
            console.error('Erro ao listar usuarios responsaveis', error);
            return undefined;
        }
    }

    public async deleteChannel(id: number): Promise<unknown> {
        const headers = await this.getHeaders('application/json');
        try {
            const response = await ky.delete(`https://dev.investor.api.vegait.com/api/1/chat-topic-user/${id}`, {
                headers,
            });
            return response;
        } catch(error) {
            console.error('Erro ao deletar canal', error)
        }
    }

    public async listChatsTopics(): Promise<undefined> {
        const headers = await this.getHeaders('application/json');
        try {
            const response = await ky.get(`https://dev.investor.api.vegait.com/api/1/chat-topic/admin-chanel/452/1`, { headers });
            return response.json(); 
        } catch (error) {
            console.error('Erro ao listar os chats topics', error);
            return undefined;
        }
    }

    public async sendMessage(ChatTopicId: number, UserId: number, Message: string, File?: any): Promise<any> {
        const headers = await this.getHeaders('application/json');
        try {
            const response = await ky.post(`https://dev.investor.api.vegait.com/api/1/chat`, {
                json: { ChatTopicId, UserId, Message, File },
                headers
            });
            return response.json(); 
        } catch (error) {
            console.error('Erro ao enviar mensagem', error);
            return undefined;
        }
    }
    



    public async postMessage(email: string, password: string): Promise<undefined> {
        const headers = await this.getHeaders('application/json');
        try {
            const response = await ky.post(`https://dev.investor.api.vegait.com/api/1/auth/admin`, { json: { email, password }, headers });
            return response.json();
        } catch (error: any) {
            if (error.response.statusText === "Unauthorized") { 
                try {
                    const response = await ky.post(`https://dev.investor.api.vegait.com/api/1/auth/investor`, { json: { email, password }, headers });
                    return response.json();
                } catch (investorError) {
                    console.error('Erro ao realizar login como investidor', investorError);
                    throw investorError;
                }
            }
            console.error('Erro ao realizar login', error);
            throw error;
        }
    }
}

export default new MessagesAPI();