import { useState } from 'react';
import IconImg from "../../assets/icons/icon-img-upload.svg";
import { UploadImage } from './types';

const ImageUpload = ({ title, onImageSelect } : UploadImage ) => {
  const [image, setImage] = useState<string | null>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as string);
        onImageSelect(file); 
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div className='flex flex-col gap-2 font-main'>
        <p className='text-[#404040] font-medium'>{title}</p>
        <div className="flex flex-col items-center justify-center p-4 border border-[#E3E7FF] rounded">
        <div className="mb-4 text-center">
            {image ? (
            <img src={image} alt="Uploaded" className="w-full h-auto rounded-lg" />
            ) : (
            <div className="flex flex-col items-center">
                <img src={IconImg} alt=''/>
            </div>
            )}
        </div>
        </div>
        <div className='flex justify-end'>
            <label className="px-4 py-2 text-[#F28E00] font-medium bg-[#FCFCFA] border rounded-lg cursor-pointer font-main">
                Selecionar imagem
                <input type="file" className="hidden" onChange={handleImageChange} />
            </label>
        </div>
    </div>
  );
};

export default ImageUpload;
