/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { SetStateAction, useState } from 'react';
import Modal from 'react-modal';
import FilterIcon from "../../assets/icons/filtro.svg"
import CloseIcon from "../../assets/icons/close-icon.svg"
import Button from '../Button';
import MultiSelect from '../MultiSelect';

Modal.setAppElement('#root');

const ModalFilter = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);

    const handleMultiSelectChange = (values: SetStateAction<never[]>) => {
        setSelectedValues(values);
    };


  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const data = [
    { name: 'teste', category: 1 },
    { name: 'exemplo', category: 2 },
  ];

  return (
    <div>
      <button
        className="flex items-center w-[170px] gap-2 p-2 px-4 
        rounded-lg border border-[#E3E7FF] bg-[#FCFCFA] text-[#F28E00] outline-none"
        onClick={openModal}
      >
         Filtrar canais <img src={FilterIcon} alt="" />
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Filtro de Canais"
        className="bg-white rounded-lg p-6 shadow-lg max-w-md mx-auto my-8 w-1/2"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <div className='flex flex-col gap-6'>
            <div className='flex justify-between'>
                <div>
                    <h2 className="text-xl font-bold mb-4">Filtrar mensagens</h2>
                </div>
                <div onClick={closeModal} className='cursor-pointer'>
                    <img src={CloseIcon} alt='' />
                </div>
            </div>
            
            <div className='flex flex-col gap-6'>
                <div className='flex gap-6 justify-between'>
                    <div className='w-1/2'>
                        <MultiSelect 
                            label="Canais"
                            data={data}
                            onChange={handleMultiSelectChange}
                            style={{ width: '100%' }} 
                            register={undefined} 
                            errors={undefined} 
                            message={undefined}                    
                        />
                    </div>
                    <div className='flex justify-end items-end'>
                        <Button
                            backGroundColor='#F6F7FF' 
                            title="Limpar filtro" 
                            color="#8A97B1" 
                            borderRadius={8}                        
                        />
                    </div>
                </div>
                
                <div className='bg-[#F4F5F7] p-2 px-4'>
                    <p>Nenhum canal selecionado</p>
                </div>

            </div>
            <div className='flex gap-4 justify-end'>
                <div>
                    <Button
                        backGroundColor='#F6F7FF' 
                        title="Cancelar" 
                        color="#8A97B1" 
                        borderRadius={8}    
                        onClick={closeModal}                    
                    />
                </div>
                <div>
                    <Button
                        backGroundColor='#F28E00' 
                        title="Filtrar" 
                        color="#FAFAFC" 
                        borderRadius={8}                        
                    />
                </div>
            </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalFilter;
