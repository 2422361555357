import ActionsMenu from '../../../components/Table/ActionsMenu';

export function getColumns( 
    viewReport?: ((row: undefined) => void) | null,
    toggleIsEdit?: ((row: undefined) => void) | null,
    deleteHotelChain?: ((row: undefined) => void) | null,
) {
    return [
        {
            name: 'Total de pontos',
            selector: (row: { total_de_pontos: number }) => (
                <span>{row.total_de_pontos}</span>
            ),
        },
        {
            name: 'Unidade',
            selector: (row: { unitdade: number }) => (
                <span>{row.unitdade}</span> 
            ),
        },
        {
            name: 'Título',
            selector: (row: { titulo: string }) => (
                <span>{row.titulo}</span>
            ),
        },
        {
            name: 'Detalhamento',
            selector: (row: { detalhamento: string }) => (
                <span>{row.detalhamento}</span>
            ),
        },
        {
            name: 'Entrada ou saída?',
            selector: (row: { entradas: string }) => (
                <span>{row.entradas}</span>
            ),
        },
        {
            name: 'Tipo de inclusão',
            selector: (row: { Tipo: string }) => (
                <span>{row.Tipo}</span>
            ),
        },
        {
            name: 'Data da inclusão',
            selector: (row: { data: string }) => (
                <span>{row.data}</span>
            ),
        },
      
        {
            name: '',
            width: '60px',
            cell: (row: undefined) => (
                <ActionsMenu
                    onView={viewReport ? () => viewReport(row) : null}
                    onEdit={toggleIsEdit ? () => toggleIsEdit(row) : null}
                    onDelete={deleteHotelChain ? () => deleteHotelChain(row) : null}
                />
            ),
        },
    ];
}
