import CircleGreen from "../../assets/icons/circle-green.svg";
import CircleRed from "../../assets/icons/circle-red.svg";
import CircleYellow from "../../assets/icons/circle-yellow.svg";
import CircleGray from "../../assets/icons/circle-gray.svg";
import CircleGreenLigth from "../../assets/icons/circle-green-ligth.svg"
import ActionsMenu from '../../components/Table/ActionsMenu';

export function getColumns(
    viewReport?: ((row: undefined) => void) | null,
    toggleIsEdit?: ((row: undefined) => void) | null,
    deleteHotelChain?: ((row: undefined) => void) | null,
) {
    return [
        {
            name: 'Nome',
            selector: (row: { name: string }) => (
                <span>{row.name}</span>
            ),
        },
        {
            name: 'Pontos para resgate',
            selector: (row: { points: number }) => (
                <span>{row.points}</span> 
            ),
        },
        {
            name: 'Qtd. de unidades',
            selector: (row: { qtd_unities: number }) => (
                <span>{row.qtd_unities}</span>
            ),
        },
        {
            name: 'Status',
            selector: (row: { status: string }) => {
                let statusText;
                let statusImage;
                let statusStyle = {};
                switch (row.status) {
                    case 'Ativo':
                        statusText = 'Ativo';
                        statusImage = CircleGreen;
                        break;
                    case 'Rascunho':
                            statusText = 'Rascunho';
                            statusImage = CircleRed;
                            statusStyle = { color: '#D42A2A' };
                        break;
                    case 'Inativo':
                        statusText = 'Inativo';
                        statusImage = CircleGray;
                        break;
                    case 'Sem unidades disponíveis':
                        statusText = 'Sem unidades disponíveis';
                        statusImage = CircleYellow;
                        break;
                    case 'Arquivado':
                        statusText = 'Arquivado';
                        statusImage = CircleGreenLigth;
                        break;
                    default:
                        statusText = '';
                        statusImage = null;
                }
                return (
                    <span className="font-main" style={statusStyle}>
                        {
                        statusText && statusImage && 
                        <div className="flex gap-2">
                                <img src={statusImage} alt={statusText} /> 
                                {statusText}

                        </div>
                        }
                    </span>
                );
            },
        },
        {
            name: '',
            width: '60px',
            cell: (row: undefined) => (
                <ActionsMenu
                    onView={viewReport ? () => viewReport(row) : null}
                    onEdit={toggleIsEdit ? () => toggleIsEdit(row) : null}
                    onDelete={deleteHotelChain ? () => deleteHotelChain(row) : null}
                />
            ),
        },
    ];
}
