import { HeaderProps } from "./types";
// import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowBack from "../../assets/icons/arrow-back.svg";
import { useNavigate } from "react-router-dom";

export default function Header({ title, subTitle, arrowIcon }: HeaderProps) {
    const navigate = useNavigate();
    return(
        <div className="flex flex-col gap-2 font-main">
            <div className="flex items-center  w-[100vh] sm:flex-col items-start w-full text-xs"> 
                {
                arrowIcon ? (
                    <div onClick={() => navigate(-1)}>
                        <img src={ArrowBack} className="cursor-pointer"/>
                    </div>
                ) : (
                    null
                )
            }
                <h1 className="text-[#404040] font-bold leading-normal font-main">
                    {title}
                </h1>
            </div>

            <div className="text-[#8A97B1] leading-normal font-normal text-sm font-main">
                <p>{subTitle}</p>
            </div>
        </div>
    )
}
