
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod';
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import ImageUpload from "../../../components/ImageUpload";
import TextEditor from "../../../components/TextEditor";
import Button from "../../../components/Button";
import StatmenteAPI from "../../../api/statement/statement";

export default function NewStatement() {
    const navigate = useNavigate();
    const location = useLocation();
    const isEdit = location.state?.isEdit;
    const dataEdit = location.state?.data;
    
    const [selectedImage, setSelectedImage] = useState<File | null>(null) 
    const [editorContent, setEditorContent] = useState<string>("");

    const newChannelCreateSchema = z.object({
        title: z.string().min(1, 'Título é obrigatório'),
        dataPublishing: z.string().min(1, 'Data é obrigatório'),
    })
      
    type NewChannelsSchema = z.infer<typeof newChannelCreateSchema>

    const { register, handleSubmit, formState: { errors }, setValue } = useForm<NewChannelsSchema>({
        resolver: zodResolver(newChannelCreateSchema),
    });

    async function handleSubmitForm(data:NewChannelsSchema)  {
        if (isEdit) {
            const id = '1'
            const response = await StatmenteAPI.editStatement(id, data.dataPublishing, data.title, selectedImage, editorContent)
            navigate(-1)
            return response
        } else {
            const response = await StatmenteAPI.createNewStatement(data.dataPublishing, data.title, selectedImage, editorContent )
            console.log('response', response)
            navigate(-1)
            return response
        }
    }

    useEffect(() => {
        if (isEdit && dataEdit) {
            setValue('title', dataEdit[0].name);
        }
    }, [isEdit, dataEdit, setValue]);
    
    return (
        <div className="flex flex-col justify-between h-screen pl-10">
            <div>
                <div className="flex w-full">
                    <div className="w-screen">
                        <Header
                            title={isEdit ? 'Editar comunicado' : 'Cadastrar Comunicado'}
                            arrowIcon={true}
                        />
                    </div>
                </div>
                <form id="my-form" onSubmit={handleSubmit(handleSubmitForm)}>
                    <div className="flex flex-col gap-6 w-full shadow-custom rounded-lg p-6">
                        <div className="flex gap-6">
                            <div className="w-1/2">
                                <Input 
                                    label="Título" 
                                    placeholder="Digite o título do comunicado" 
                                    type="text"  
                                    register={register("title")}
                                    errors={errors.title}                 
                                />  
                            </div>
                            <div className="w-1/2">
                                <Input 
                                    label="Publicação" 
                                    placeholder="Informe a data de publicação" 
                                    type="date"    
                                    register={register("dataPublishing")}
                                    errors={errors.dataPublishing}                     
                                />  
                            </div>
                        </div>
                        <div>
                            <ImageUpload 
                                title="Foto da capa"
                                onImageSelect={setSelectedImage}
                            />
                        </div>
                        <div>
                            <TextEditor 
                                label="" 
                                value={editorContent} 
                                onChange={setEditorContent}
                            />
                        </div>
                    </div>
                </form>
                
            </div>
            <div className="p-10">
                <div className="flex justify-between ">
                    <Button 
                        title="Voltar" 
                        onClick={() => navigate(-1)}
                        backGroundColor="#fff" 
                        color="#F28E00" 
                        borderRadius={8} 
                    />
                    <Button 
                        title={isEdit ? 'Salvar comunicado' : 'Cadastrar comunicado'} 
                        backGroundColor="#F28E00"
                        color="#FAFAFC"
                        borderRadius={8} 
                        form="my-form"
                        type="submit"
                    />

                </div>
            </div>

            
        </div>
    );
}
