import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function SelectEnterprise() {
const hotels = [
    { label: 'IL Campanario', year: 2024 },
  ];
  
    return(
            <div className='w-[352px] h-[56px] border-l-4 border-[#076BEF] font-main sm:w-[100%]'>
                <span className='text-sm top-3 px-1 text-[#076BEF]'>Você está vendo</span>
                <Autocomplete
                    sx={{
                        borderBottom: 'none'
                    }}
                    options={hotels}
                    id="enterprise-select"
                    disableClearable
                    renderInput={(params) => (
                        <TextField {...params} variant="standard" sx={{
                            paddingLeft: '5px'
                        }} />
                    )}
                />  
            </div>
    )
}