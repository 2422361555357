/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { Grid2 } from "@mui/material";
import Header from "../../../components/Header";
import Button from "../../../components/Button";
import SelectEnterprise from "../../../components/SelectEnterprise";
import Input from "../../../components/Input";
import Table from "../../../components/Table";
import { getColumns } from './columns';
import { useEffect, useState } from "react";
import PageSize from "../../../components/Table/PageSize";
import Pagination from "../../../components/Table/Pagination";
import MultiSelect from "../../../components/MultiSelect";
import { useNavigate } from 'react-router-dom';
import DeleteModal from "../../../components/DeleteModal";
import channel from "../../../api/channel/channel";
import { ResponseNumberOfChannels } from "./types";
import { ListChatsTopic } from "../../../api/channel/types";

export default function Channels() {
    const navigate = useNavigate();
    const useData = [{name: 'Reserva', category: 1}]
    const [pageSize, setPageSize] = useState<number>(10);
    const [numberOfChannel, setNumberOfChannel] = useState<ResponseNumberOfChannels>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [numberOfPages, setNumberOfPages] = useState<number>(0);
    const [selectedValues, setSelectedValues] = useState([]);
    const [deletingContract, setDeletingContract] = useState<IListContractResponse | null>(null)
    const [dataChannel, setDataChannel] = useState<ListChatsTopic>([]);
    
    const dataEdit = [
        { name: 'Reserva', category: 1 },
    ];
    const data = [
        { name: 'Reserva', category: 1 },
        { name: 'Reserva', category: 2 },
    ];

    const handleMultiSelectChange = (values) => {
        setSelectedValues(values);
    };

    const editChannel = (userParams) => {
        navigate('/editar-canal', { state: { isEdit: true, data: userParams } });
    };

    async function deleteItem(deletingItem) {
        const { Id } = deletingItem;
    
        if (!Id) {
            console.error("Id não definido");
            return; 
        }
    
        try {
            const response = await channel.deleteChannel(Id);
            if (response) {
                return response;
            } else {
                console.error("Falha ao deletar canal.");
            }
        } catch (error) {
            console.error('Erro ao deletar canal', error);
        } finally {
            setDeletingContract(null); 
        }
    }
    

    async function listChannel() {
        try {
            const response = await channel.listChannels()
            if (response) setDataChannel(response)
        } catch(error) {
            console.error('Erro ao listar canais', error)
        }
    }
 
    async function listOfNumberChannel() {
        try {
            const response = await channel.listNumberOfChannels();
            if (response) {
                setNumberOfChannel(response);
            } else {
                console.error('Nenhum dado foi retornado de listNumberOfChannels');
            }
        } catch (error) {
            console.error('Erro inesperado ao listar número de canais', error);
        }
    }

    useEffect(() => {
        listChannel()
        listOfNumberChannel()
    },[])
    
    return(
    <Grid2 container spacing={1} mt={5} ml={5} mr={1}>
            <div className="flex w-full">
                <div className="w-1/2">
                    <Header
                        title="Canais"
                        subTitle="Visualize, crie e gerencie os canais de atendimento disponibilizados aos usuários."
                        arrowIcon={false}
                    />
                </div>
                <div className="flex flex-col items-end  gap-5 w-1/2">
                    <div>
                        <SelectEnterprise />
                    </div>

                    <div>
                        <Button
                            title=" + Novo Canal"
                            color="#fff"
                            backGroundColor="#F28E00"
                            borderRadius={8}
                            onClick={() => navigate('/novo-canal')}
                        />

                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
                <div className="flex gap-6">
                    <div className="w-2/4">
                        <Input 
                            label="Nome"
                            placeholder="Digite o nome do canal"
                            type="text"
                        />
                    </div>
                   <div className="w-2/4">
                    <MultiSelect 
                        label="Status"
                        data={data} 
                        onChange={handleMultiSelectChange} 
                        style={{ width: '100%' }}
                    />
                    </div>
                </div>
                    <div className="flex justify-end gap-4">
                        <Button
                            title="Limpar"
                            color="#F28E00"
                            backGroundColor="#FCFCFA"
                            borderColor="#E3E7FF"
                            borderRadius={8}
                            onClick={() => console.log('cliquei')}
                        />
                        <Button
                            title="Buscar"
                            color="#fff"
                            backGroundColor="#F28E00"
                            borderRadius={8}
                            onClick={() => console.log('cliquei')}
                        />
                    </div>
            </div>

            <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
                <div className="flex justify-between">
                    <div className="text-[#6D7882]">
                        <p><span className="font-bold">{numberOfChannel.registrationitem}</span> itens cadastrados</p>
                    </div>
                    <div className="flex gap-4 items-center">
                        <PageSize
                                pageSize={pageSize}
                                setPageSize={(size) => {
                                setPageSize(size);
                                setCurrentPage(1);
                            }}
                        />
                        <p className="text-[#6D7882]">Itens por página</p>
                    </div>
                </div>

               <Table 
                    data={dataChannel}
                    columns={getColumns(
                        editChannel, 
                        setDeletingContract
                    )}
                    hidePagination
               />
               <Pagination
                    pageCount={numberOfPages}
                    currentPage={currentPage - 1}
                    setPage={(page) => {
                        setCurrentPage(page + 1); 
                    }}
                    setPagePrevious={page => setCurrentPage(page + 1)}
               />
            </div>
            {
                deletingContract && (
                    <DeleteModal 
                        itemName={deletingContract.name}
                        confirm={() => deleteItem(deletingContract)}
                        cancel={() => setDeletingContract(null)}
                    />
                )
            }
    </Grid2>
    )
}