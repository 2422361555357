/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useNavigate } from "react-router-dom"
import Button from "../../../components/Button"
import Header from "../../../components/Header"
import Input from "../../../components/Input"
import MultiSelect from "../../../components/MultiSelect"
import SelectEnterprise from "../../../components/SelectEnterprise"
import { Grid2 } from "@mui/material"
import { useState } from "react"
import PageSize from "../../../components/Table/PageSize";
import Table from "../../../components/Table"
import Pagination from "../../../components/Table/Pagination"
import { getColumns } from "./columns"
import DeleteModal from "../../../components/DeleteModal"
import { z } from 'zod'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod';
import RadioInput from "../../../components/RadioInput"
import IconCirlceGreen from '../../../assets/icons/circle-green-ligth.svg';
import IconCircleWhite from '../../../assets/icons/circle-white.svg';

enum inputType {
    ENTRY = 'ENTRY',
    OUTPUT = 'OUTPUT'
}
export default function Points() {
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [numberOfPages, setNumberOfPages] = useState<number>(0);
    const [deletingContract, setDeletingContract] = useState<null>(null);
    const [selectedValues, setSelectedValues] = useState([]);
    const [itemType, setItemType] = useState<inputType>();

    const useData = [
        {total_de_pontos: 220, unitdade: 199, titulo: 'Pontos recebidos', detalhamento: 'Aniversário de pool', entradas: 'Entrada', Tipo: 'Manual', data: '20/08/2024'},
        {total_de_pontos: 220, unitdade: 199, titulo: 'Pontos recebidos', detalhamento: 'Aniversário de pool', entradas: 'Entrada', Tipo: 'Manual', data: '20/08/2024'},
        {total_de_pontos: 220, unitdade: 199, titulo: 'Pontos recebidos', detalhamento: 'Aniversário de pool', entradas: 'Entrada', Tipo: 'Manual', data: '20/08/2024'},
    ]
    const dataEdit = [
        { name: 'teste', category: 1 },
    ];
    const data = [
        { name: 'todos', category: 1 },
    ];
    const handleMultiSelectChange = (values) => {
        setSelectedValues(values);
    };

    const viewReport = (userParam: unknown) => {
        navigate('/programa-de-fidelidade/visualizar-relatorios', { state: { isEdit: true, data: dataEdit } });
    }

    const editChannel = () => {
        navigate('/programa-de-fidelidade/editar-beneficio', { state: { isEdit: true, data: dataEdit } });
    };

    function deleteItem(deletingItem) {
        console.log('dados', deletingItem)
        setDeletingContract(null)
    }


    const newChannelCreateSchema = z.object({
        title: z.any(),
        responsibleUsers: z.any(),
    })
      
    type NewChannelsSchema = z.infer<typeof newChannelCreateSchema>

    const { register, handleSubmit, control, formState: { errors } } = useForm<NewChannelsSchema>({
        resolver: zodResolver(newChannelCreateSchema),
    });

    function handleSubmitForm(data:NewChannelsSchema)  {
      
    }

    const handleChange = (value: string) => {
        console.log('entrei')
        setSelectedValueInput(value);
    };


    return(
        <Grid2 container spacing={1} mt={5} ml={5} mr={1}>
            <div className="flex w-full sm:flex-col">
                <div className="w-1/2">
                    <Header
                        title="Pontos"
                        subTitle="Realize a inclusão dos pontos para os proprietárioss"
                        arrowIcon={false}
                    />
                </div>
                <div className="flex flex-col items-end  gap-5 w-1/2 sm:w-[100%]">
                    <div>
                        <SelectEnterprise />
                    </div>

                    <div>
                        <Button
                            title="+ Nova inclusão de pontos"
                            color="#fff"
                            backGroundColor="#F28E00"
                            borderRadius={8}
                            onClick={() => navigate('/programa-de-fidelidade/incluir-pontos')}
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
                <div className="flex gap-6 sm:flex-col">
                    <div className="w-2/4 sm:w-[100%]">
                    <Controller
                        name="responsibleUsers"
                        control={control}
                            render={({ field }) => (
                                <MultiSelect
                                    label="Unidade"
                                    data={data}
                                    onChange={(values: SetStateAction<never[]>) => {
                                        field.onChange(values);
                                        handleMultiSelectChange(values);
                                    }}
                                    style={{ width: '100%' }}
                                    register={field}
                                    errors={errors.responsibleUsers}
                                    message="Campo obrigatório"
                                />
                            )}
                    />
                    </div>
                <div className="w-2/4 sm:w-[100%]">
                <Controller
                        name="responsibleUsers"
                        control={control}
                            render={({ field }) => (
                                <MultiSelect
                                    label="Tipo de inclusão"
                                    data={data}
                                    onChange={(values: SetStateAction<never[]>) => {
                                        field.onChange(values);
                                        handleMultiSelectChange(values);
                                    }}
                                    style={{ width: '100%' }}
                                    register={field}
                                    errors={errors.responsibleUsers}
                                    message="Campo obrigatório"
                                />
                            )}
                    />
                    </div>
                <div className="flex items-end gap-4 w-2/4 sm:w-[100%]">
                <button
                    className={`
                        h-[40px]
                        flex items-center gap-3 border ${itemType === inputType.ENTRY ? 'border-[#B6BA3A]' : 'border-white'}`}
                        type='button'
                        onClick={() => setItemType(inputType.ENTRY)}>
                        {itemType === inputType.ENTRY ? <img src={IconCirlceGreen} /> : <img src={IconCircleWhite} />} Entrada
                </button>
        
                <button
                    type='button'
                    className={`
                    h-[40px] flex items-center gap-3 border ${itemType === inputType.OUTPUT ? 'border-[#B6BA3A]' : 'border-white'}`}
                    onClick={() => setItemType(inputType.OUTPUT)}>
                    {itemType === inputType.OUTPUT ? <img src={IconCirlceGreen} /> : <img src={IconCircleWhite} />} Saída
                 </button>
                    </div>
                </div>
                    <div className="flex justify-end gap-4">
                        <Button
                            title="Limpar"
                            color="#F28E00"
                            backGroundColor="#FCFCFA"
                            borderColor="#E3E7FF"
                            borderRadius={8}
                            onClick={() => console.log('cliquei')}
                        />
                        <Button
                            title="Buscar"
                            color="#fff"
                            backGroundColor="#F28E00"
                            borderRadius={8}
                            onClick={() => console.log('cliquei')}
                        />
                    </div>
            </div>

            <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
                <div className="flex justify-between">
                    <div className="text-[#6D7882]">
                        <p><span className="font-bold">{useData.length}</span> itens cadastrados</p>
                    </div>
                    <div className="flex gap-4 items-center">
                        <PageSize
                                pageSize={pageSize}
                                setPageSize={(size) => {
                                setPageSize(size);
                                setCurrentPage(1);
                            }}
                        />
                        <p className="text-[#6D7882]">Itens por página</p>
                    </div>
                </div>

            <Table 
                    data={useData}
                    columns={getColumns(
                        viewReport,
                        editChannel, 
                        setDeletingContract
                    )}
                    hidePagination
            />
            <Pagination
                pageCount={numberOfPages}
                currentPage={currentPage - 1}
                setPage={(page) => {
                        setCurrentPage(page + 1); 
                }}
                setPagePrevious={page => setCurrentPage(page + 1)}
            />
            </div>
        {
            deletingContract && (
                <DeleteModal 
                    itemName={deletingContract.name}
                    confirm={() => deleteItem(deletingContract)}
                    cancel={() => setDeletingContract(null)}
                />
            )
        }
</Grid2>
    )
}