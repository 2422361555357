/* eslint-disable @typescript-eslint/no-explicit-any */
import ky from 'ky';
// const API_URL = import.meta.env.VITE_APP_API_URL;

class StatmenteAPI {
    constructor() {
        
    }
    private async getHeaders(contentType?: string) {
        const headers: Record<string, string> = {
            'Accept': 'application/json',
        };
    
        const token = localStorage.getItem('token');
        if (token) {
            headers['Authorization'] = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VyVHlwZSI6IjEiLCJ1c2VyX2lkIjoiNDg4IiwibmJmIjoxNzI4NTcwNjU3LCJleHAiOjE3Mjg2NTcwNTcsImlhdCI6MTcyODU3MDY1NywiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo1MjY4IiwiYXVkIjoibG9jYWxob3N0LWludmVzdG9yLXdlYiJ9.RBLLHz071hryD-NNfvhFNhytPIU5RezwKnsldOgS38I`;
        }
    
        if (contentType) {
            headers['Content-Type'] = contentType;
        }
    
        return headers;
    }

    // public async createNewStatement(Schedule: string, Name: string, File: File | null, UserIds: string[]): Promise<unknown> {
    public async createNewStatement(Schedule: string, Name: string, File: File | null, Text: string): Promise<unknown> {
        const formData = new FormData();
        formData.append('Schedule', Schedule);
        formData.append('Name', Name);
        formData.append('Text', Text);
        // formData.append('Schedule', Schedule);
        // UserIds.forEach(id => formData.append('UserIds[]', id));
        
        if (File) {
            formData.append('File', File);
        }

        try {
            const headers = await this.getHeaders();
            headers['Content-Type'] = 'multipart/form-data';
            // const data = await ky.post(`${API_URL}/1/notification`, { body: formData, headers });
            const data = await ky.post(`https://dev.investor.api.vegait.com/api/1/notification`, { body: formData, headers });
            return data;
        } catch (error) {
            console.error('Erro ao cadastrar novo canal', error);
            throw error;
        }
    }


    public async listStatement(): Promise<unknown> {
        const headers = await this.getHeaders('application/json');
        try {
            const response = await ky.post(`https://dev.investor.api.vegait.com/api/1/notification/filter-notification`, { headers });
            return response.json(); 
        } catch (error) {
            console.error('Erro ao listar comunicados', error);
            return undefined; 
        }
    }

    public async deleteStatement(id: string): Promise<unknown> {
        const headers = await this.getHeaders('application/json');
        try {
            const response = await ky.delete(`https://dev.investor.api.vegait.com/api/1/notification/${id}`, {
                headers,
            });
            return response;
        } catch(error) {
            console.error('Erro ao deletar comunicado', error)
        }
    }

    public async editStatement(id: string, Schedule: string, Name: string, File: File | null, UserIds: string[] ): Promise<unknown> {
        const formData = new FormData();
        formData.append('Schedule', Schedule);
        formData.append('Name', Name);
        formData.append('Schedule', Schedule);
        formData.append('Schedule', Schedule);
        UserIds.forEach(id => formData.append('UserIds[]', id));
        
        if (File) {
            formData.append('File', File);
        }

        try {
            const headers = await this.getHeaders();
            headers['Content-Type'] = 'multipart/form-data';
            const data = await ky.put(`https://dev.investor.api.vegait.com/api/1/notification/${id}`, { body: formData, headers });
            return data;
        } catch (error) {
            console.error('Erro ao editar comunicado', error);
            throw error;
        }
    }
}

export default new StatmenteAPI();