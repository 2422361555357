import ky from 'ky';
import { IUserData } from './types';
// const API_URL = import.meta.env.VITE_APP_API_URL;

class UserApi {
    constructor() {}

    private async getHeaders(contentType?: string) {
        const headers: Record<string, string> = {
            'Accept': 'application/json',
        };
    
        const token = localStorage.getItem('token');
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
    
        if (contentType) {
            headers['Content-Type'] = contentType;
        }
    
        return headers;
    }

    public async listUserData(): Promise<IUserData | undefined> {
        const headers = await this.getHeaders('application/json');
        const userID = localStorage.getItem('userId')
        try {
            const response = await ky.get(`https://dev.investor.api.vegait.com/api/1/user/${userID}`, { headers });
            return response.json()
        } catch(error) {
            console.error('Erro ao listar dados do usuário', error)
        }
    }
}

export default new UserApi();