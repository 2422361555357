/* eslint-disable @typescript-eslint/no-explicit-any */
import Header from "../../../../components/Header";
import Input from "../../../../components/Input";
import IconSelector from "../../../../components/IconSelector";
import MultiSelect from "../../../../components/MultiSelect";
import { SetStateAction, useEffect, useState } from "react";
import Button from "../../../../components/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { z } from 'zod'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod';
import channel from "../../../../api/channel/channel";
import React from "react";
import CustomToast from "../../../../components/Toast";
import BreadcrumbsPage from "../../../../components/Breadcrumbs";

export default function NewChannel() {
    const navigate = useNavigate();
    const location = useLocation();
    const isEdit = location.state?.isEdit;
    const dataEdit = location.state?.data;

    const [listUsersResponsible, setListUsersResponsible] = useState<any>([]);
    const [selectedIconFile, setSelectedIconFile] = useState<File | null>(null); 

    const handleIconSelect = (icon: React.ReactNode) => {
        if (React.isValidElement(icon) && icon.props?.src) {
          fetch(icon.props.src)
            .then((response) => response.blob())
            .then((blob) => {
              const file = new File([blob], "icon.png", { type: blob.type });
              setSelectedIconFile(file); 
            })
            .catch((error) => {
              console.error("Erro ao buscar o ícone", error);
            });
        } else {
          console.error("Ícone selecionado inválido.");
        }
    };

    const [selectedValues, setSelectedValues] = useState([]);
    const handleMultiSelectChange = (values: SetStateAction<never[]>) => {
        setSelectedValues(values);
    };

    const newChannelCreateSchema = z.object({
        name: z.string().min(1, 'Nome é obrigatório'),
        responsibleUsers: z.any(),
    })
      
    type NewChannelsSchema = z.infer<typeof newChannelCreateSchema>

    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm<NewChannelsSchema>({
        resolver: zodResolver(newChannelCreateSchema),
    });

    const handleToastSuccess = () => {
        return <CustomToast message="Canal cadastrado com sucesso" type="success" />;
    };

    const handleToastSuccessEdit = () => {
        return <CustomToast message="Canal atualizado com sucesso" type="success" />;
    };

    async function handleSubmitForm(data:NewChannelsSchema)  {

        const idValue = data.responsibleUsers.map((user: { Id: number; }) => user.Id);
        const id = dataEdit.Id;

        if (isEdit) {
            const response = await channel.editChannel(id,selectedIconFile, data.name, idValue)
            handleToastSuccessEdit()
            navigate(-1)
            return response
        } else {
            const response = await channel.postNewChannel(selectedIconFile, data.name, idValue);
            handleToastSuccess()
            navigate(-1)
            return response
        }
    }

    async function responsibleUsersList() {
        try {
            const response = await channel.getResponsibleUsers()
            setListUsersResponsible(response)
            return response
        } catch (error) {
            console.error('Erro ao listar usuários responsáveis', error)
        }
    }

    useEffect(() => {
        responsibleUsersList()
    },[])

    useEffect(() => {
        if (isEdit && dataEdit) {
            setValue('name', dataEdit.Title);
        }
    }, [isEdit, dataEdit, setValue]);
    
    return (
        <div className="flex flex-col justify-between h-screen pl-10">
            <div>
                <div className="flex flex-col w-full gap-2 pt-4">
                    <div>
                        <BreadcrumbsPage />
                    </div>
                    <div className="w-1/2">
                        <Header
                            title={isEdit ? 'Editar canal' : 'Cadastrar canal'}
                            arrowIcon={true}
                        />
                    </div>
                </div>

                <div className="flex flex-col gap-6 w-full shadow-custom rounded-lg p-6">
                    <form id='my-form' className="flex flex-col gap-6" onSubmit={handleSubmit(handleSubmitForm)}>
                        <div className="flex gap-4">
                            <div>
                                <IconSelector 
                                    onIconSelect={handleIconSelect}
                                    initialIconUrl={dataEdit?.ChannelIcon}
                                />
                            </div>
                            <div className="w-full">
                                <Input
                                    label="Nome"
                                    type="text"
                                    placeholder="Digite o nome do canal"
                                    register={register("name")}
                                    errors={errors.name}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col gap-4 rounded-lg">
                            <Controller
                                name="responsibleUsers"
                                control={control}
                                render={({ field }) => (
                                    <MultiSelect
                                        label="Usuários responsáveis"
                                        data={listUsersResponsible}
                                        onChange={(values: SetStateAction<never[]>) => {
                                            field.onChange(values);
                                            handleMultiSelectChange(values);
                                        }}
                                        style={{ width: '100%' }}
                                        register={field}
                                        errors={errors.responsibleUsers}
                                        message="Campo obrigatório"
                                    />
                                    )}
                                />
                            {
                                selectedValues.length > 0 ? (
                                    <div>
                                        <p>{selectedValues.length} usuário(s) selecionado(s)</p>
                                    </div>
                                ) : (
                                    <div className="bg-[#F4F5F7] p-2 px-4 rounded-md text-[#404040]">
                                        <p>Nenhum usuário selecionado</p>
                                    </div>
                                )
                            }
                        </div>
                    </form>
                </div>
            </div>

            <div className="flex justify-between mt-auto shadow-custom p-10">
                <Button
                    title="Voltar"
                    onClick={() => navigate(-1)}
                    backGroundColor={"#FCFCFA"}
                    color={"#F28E00"}
                    borderRadius={8}
                />
                <Button
                    title={isEdit ? 'Salvar canal' : 'Cadastrar canal'}
                    backGroundColor={"#F28E00"}
                    color={"#FAFAFC"}
                    borderRadius={8}
                    type="submit"
                    form="my-form"
                />
            </div>
        </div>
    );
}
