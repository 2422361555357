/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useState } from 'react';
import { Box, Checkbox, ListItemText, MenuItem, Select, OutlinedInput, Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const MultiSelect = ({ data, onChange, style, label, register, errors, message }) => {
  console.log("data", data)
  const [selected, setSelected] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const newSelected = typeof value === 'string' ? value.split(',') : value;
    setSelected(newSelected);
    onChange(newSelected); 
  };

  const handleDelete = (valueToDelete) => () => {
    const newSelected = selected.filter((value) => value.Name !== valueToDelete.Name);
    setSelected(newSelected);
    onChange(newSelected); 
  };

  return (
    <div className='flex flex-col gap-2 font-main'>
        <label className='font-medium text-[#404040]'>
            {label}
        </label>
        <Select
        multiple
        value={selected}
        onChange={handleChange}
        input={<OutlinedInput label="Select" {...register} />}
        renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
                <Chip
                key={value.Name}
                label={value.Name}
                deleteIcon={<CloseIcon onMouseDown={(event) => event.stopPropagation()} />}
                onDelete={handleDelete(value)}
                />
            ))}
            </Box>
        )}
        sx={{ width: '100%', ...style }}
        >
        {data?.map((item) => (
            <MenuItem key={item.Name} value={item}>
            <Checkbox checked={selected.indexOf(item) > -1} />
            <ListItemText primary={item.Name} />
            </MenuItem>
        ))}
        </Select>
        {errors && <p className="text-red-500 text-sm">{message}</p>}
    </div>
  );
};

export default MultiSelect;
