import { useNavigate } from "react-router-dom";
import IconLogo from "../../assets/icons/logo-vega.png";
import Button from "../Button";
import Input from "../Input";
import LoginAPI from "../../api/login/login"
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod';

export default function Login() {
    const navigate = useNavigate();

    const newLoginSchema = z.object({
        email: z.string().min(1, 'E-mail é obrigatório'),
        password: z.string().min(1, 'Senha é obrigatório'),
    })

    type NewLoginSchema = z.infer<typeof newLoginSchema>

    const { register, handleSubmit, formState: { errors } } = useForm<NewLoginSchema>({
        resolver: zodResolver(newLoginSchema),
    });

    async function handleSubmitForm(data:NewLoginSchema)  {
        try {
            const response = await LoginAPI.postLogin(data.email, data.password)
            localStorage.setItem('token', response?.token)
            localStorage.setItem('userId', response?.userId)
            navigate('/')
            return response
        } catch (error) {
            console.error('Erro ao realizar login', error)
        }
    }

    return(
        <div className="flex flex-col gap-6 justify-center items-center bg-red-[#F4F5F7] h-[100vh] ">
            <div>
                <img src={IconLogo} width={150} height={150} alt="" />
            </div>
            <div className="flex flex-col gap-6 bg-white p-16 shadow-md rounded-lg">
                <form className="flex flex-col gap-6 w-72"  onSubmit={handleSubmit(handleSubmitForm)}>
                    <div className="flex flex-col gap-6">
                        <Input 
                            label="E-mail" 
                            placeholder="Digite seu e-mail." 
                            type="text"   
                            register={register("email")}
                            errors={errors.email}
                        />
                        <Input 
                            label="Senha" 
                            placeholder="Digite sua senha." 
                            type="text"    
                            password={true}
                            register={register("password")}
                            errors={errors.password}
                        />
                        <Button 
                            title="Entrar"
                            backGroundColor="#F28E00" 
                            color="white" 
                            borderRadius={8}      
                            type="submit"       
                        />
                </div>
                </form>
            </div>
        </div>
    )
}