import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Menu } from './types';
import { Link } from 'react-router-dom';
import UserApi from "../../api/user/user"


interface SideBarMenuProps {
    menus: Menu[];
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar)<{ open: boolean }>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));




export default function SideBarMenu({ menus }: SideBarMenuProps) {
    const theme = useTheme();
    const [open, setOpen] = React.useState<boolean>(false);
    const [userName, setUserName] = useState<string>('');

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    async function getDataUser() {
        try {
            const response = await UserApi.listUserData()
            if(response) setUserName(response.Name) 
        } catch (error) {
            console.error('Erro ao listar dados do usuário', error)
        }
    }

    function getInitials(name: string | undefined) {
        if (!name) return '';
        const words = name.split(' '); 
        if (words.length === 1) {
            return words[0].substring(0, 2).toUpperCase();
        }
        return words[0][0].toUpperCase() + words[1][0].toUpperCase();
    }

    useEffect(() => {
        getDataUser()
    },[])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar open={open} sx={{
                height: '100vh', width: '56px', position: 'relative',
                backgroundColor: '#2E406B', display: 'flex', flexDirection: 'column',
            }}>
                <Toolbar>
                    <div 
                        className='flex flex-col gap-8 h-screen'
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                    >
                        <MenuIcon sx={{cursor: 'pointer', marginTop:'15px'}} />
                        <div className='bg-[#27365B] border border-white rounded-full p-1'>
                            <p>{getInitials(userName)}</p>
                        </div>
                        {
                            menus?.map(menu => {
                                return(
                                    <>
                                      <img src={menu.imageUrl}  width={20} height={20}/>
                                    </>
                                )
                            })
                        }
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        height: '100%', 
                        backgroundColor: '#2E406B',
                        border: 'none',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    width: '100%'
                }}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon sx={{ color: '#FFF' }} /> : <ChevronRightIcon sx={{ color: '#FFF' }} />}
                    </IconButton>
                    <div className='w-full'>
                        <Accordion sx={{
                            backgroundColor: '#27365B',
                            color: "#FFF",
                            boxShadow: 'none',
                            border: 'none',
                            position: 'initial',
                        }}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon sx={{color: '#FFF'}} />}
                            >
                                <div className='flex items-center gap-4 bg-[#27365B] p-2'>
                                    <div className='bg-[#27365B] border border-white rounded-full p-1'>
                                        <p>{getInitials(userName)}</p>
                                    </div>
                                    <div>
                                        <p>{userName}</p>
                                    </div>    
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography  sx={{
                                    display: 'flex', marginLeft: '15px', color: "#D6D8E1", fontSize: '14px',
                                    fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', padding: '20px',
                                }}>
                                    Perfil
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </DrawerHeader>
                {menus.map((menu, index) => {
                    return menu.subTitles && menu.subTitles.length > 0 ? (
                        <Accordion key={index} sx={{
                            backgroundColor: '#2E406B',
                            color: "#FFF",
                            boxShadow: 'none',
                            border: 'none',
                            position: 'initial',
                            padding: '12px 16px', 
                            gap: '4px',
                            width: '100%'
                        }}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon sx={{color: '#FFF'}} />}
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                                sx={{
                                    padding: '12px 16px', gap: '4px'
                                }}
                            >
                              <div className='flex  gap-1'>
                                <img src={menu.imageUrl} width={12} />
                                <Typography sx={{mr: 5}} >{menu.title}</Typography>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                {menu.subTitles.map((subTitle, subIndex) => (
                                    <Typography key={subIndex} component={Link} to={subTitle.url} sx={{
                                        display: 'flex', marginLeft: '15px', color: "#D6D8E1", fontSize: '14px',
                                        fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', padding: '20px',
                                    }}>
                                        {subTitle.text}
                                    </Typography>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    ) : (
                        <Accordion key={index} sx={{
                            backgroundColor: '#2E406B',
                            color: "#FFF",
                            boxShadow: 'none',
                            border: 'none',
                            position: 'initial',
                            padding: '12px 16px', 
                            gap: '4px'
                        }}
                        >
                            <Box 
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                                sx={{
                                    display: 'flex',
                                    padding: '12px 16px', gap: '4px'
                                }}
                            >
                              <img src={menu.imageUrl} width={12} />
                                <Typography sx={{mr: 5, color:"#fff"}} component={Link} to={menu.url ?? '#'}>
                                    {menu.title}
                                </Typography>
                            </Box >
                        </Accordion>
                    );
                })}
            </Drawer>
        </Box>
    );
}
