import React from 'react';
import { Outlet } from 'react-router-dom';
import SideBarMenu from '../MenuLateral';
import IconMessage from "../../assets/icons/message-icon.svg";
import IconCommunity from "../../assets/icons/fone-icon.svg";
import IconFile from "../../assets/icons/icon-file.svg";
import IconBenefits from "../../assets/icons/icon-benefits.svg";

const menus = [
  {
      title: 'Atendimento',
      subTitles: [
          { text: 'Mensagens', url: '/Mensagens' },
          { text: 'Canais', url: '/Canais' }
      ],
      imageUrl: IconMessage 
  },
  {
      title: 'Comunicados',
      url: '/comunicados',
      imageUrl: IconCommunity 
  },
  {
      title: 'Arquivos',
      url: '/arquivos',
      imageUrl: IconFile 
  },
  {
      title: 'Programa de fidelidade',
      subTitles: [
          { text: 'Pontos', url: '/Pontos' },
          { text: 'Benefícios', url: '/programa-de-fidelidade' }
      ],
      imageUrl: IconBenefits 
    }
];

const Layout: React.FC = () => {
  return (
    <div className="flex h-screen">
      <SideBarMenu menus={menus} />
      <div className="flex-grow overflow-y-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
