/* eslint-disable @typescript-eslint/no-unused-vars */

import { z } from 'zod'
import { SetStateAction, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod';
import Header from "../../../../components/Header";
import Button from "../../../../components/Button";
import BreadcrumbsPage from '../../../../components/Breadcrumbs';
import Input from '../../../../components/Input';
import IconCirlceGreen from '../../../../assets/icons/circle-green-ligth.svg';
import IconCircleWhite from '../../../../assets/icons/circle-white.svg';

enum inputType {
    ENTRY = 'ENTRY',
    OUTPUT = 'OUTPUT'
}

enum typePoints {
    MANUAL = 'MANUAL',
    MASS = 'MASS'
}

enum typeUpload {
    UNITY = 'UNITY',
    OWNER = 'OWNER'
}

export default function NewPoints() {
    const navigate = useNavigate();
    const location = useLocation();
    const isEdit = location.state?.isEdit;
    const dataEdit = location.state?.data;
    const [itemType, setItemType] = useState<inputType>();
    const [typePoint, setTypePoint] = useState<typePoints>();
    const [typeUploads, setTypeUploads] = useState<typeUpload>()

    const newChannelCreateSchema = z.object({
        name: z.string().min(1, 'Nome é obrigatório'),
        responsibleUsers: z.any(),
        file: z
        .any()
        .refine(files => files.length == 1, 'Arquivo é obrigatório.')
        .refine(files => files?.[0]?.size <= 10000000, 'Tamanho máximo de 10MB.'),
        option: z.any()

    })

    type NewChannelsSchema = z.infer<typeof newChannelCreateSchema>
    const [selectedValueInput, setSelectedValueInput] = useState<string>('Novo benefício');

    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm<NewChannelsSchema>({
        resolver: zodResolver(newChannelCreateSchema),
        defaultValues: {
            name: '',
            responsibleUsers: '',
            file: '',
            option: 'Novo benefício'
        }
    });

    function handleSubmitForm(data:NewChannelsSchema)  {
        if (isEdit) {
            console.log('esta editando', data)
        } else {
            navigate(-1)
            console.log('nao esta editando', data)
        }
    }

    useEffect(() => {
        if (isEdit && dataEdit) {
            setValue('name', dataEdit[0].name);
        }
    }, [isEdit, dataEdit, setValue]);


    const handleChange = (value: string) => {
        console.log('entrei')
        setSelectedValueInput(value);
    };

    return (
        <div className="flex flex-col justify-between h-screen pl-10 pt-12">
            <div>
                <div className="flex flex-col w-full gap-2">
                    <div>
                        <BreadcrumbsPage />
                    </div>
                    <div className="w-1/2">
                        <Header
                            title={isEdit ? 'Editar benefício' : 'Incluir pontos'}
                            arrowIcon={true}
                        />
                    </div>
                </div>

                <div className="flex flex-col gap-6 w-full shadow-custom rounded-lg p-6">
                    <form id='my-form' className="flex flex-col gap-6" onSubmit={handleSubmit(handleSubmitForm)}>
                        <div className="flex gap-4 sm:flex-col">
                        <button
                            className={` h-[40px] flex items-center gap-3 border ${typePoint === typePoints.MANUAL ? 'border-[#B6BA3A]' : 'border-white'}`}
                            type='button'
                            onClick={() => setTypePoint(typePoints.MANUAL)}>
                            {typePoint === typePoints.MANUAL ? <img src={IconCirlceGreen} /> : <img src={IconCircleWhite} />} Inclusão manual
                        </button>

                        <button
                            type='button'
                            className={` h-[40px] flex items-center gap-3 border ${typePoint === typePoints.MASS ? 'border-[#B6BA3A]' : 'border-white'}`}
                            onClick={() => setTypePoint(typePoints.MASS)}>
                            {typePoint === typePoints.MASS ? <img src={IconCirlceGreen} /> : <img src={IconCircleWhite} />} Inclusão em massa
                        </button>
                        </div>

                        {
                            typePoint === typePoints.MASS ? (
                                <div>
                                    <div className='flex gap-6'>
                                        <button
                                            className={`
                                                h-[40px]
                                                flex items-center gap-3 border ${itemType === inputType.ENTRY ? 'border-[#B6BA3A]' : 'border-white'}`}
                                            type='button'
                                            onClick={() => setItemType(inputType.ENTRY)}>
                                            {itemType === inputType.ENTRY ? <img src={IconCirlceGreen} /> : <img src={IconCircleWhite} />} Inclusão por unidade
                                        </button>
        
                                        <button
                                            type='button'
                                            className={`
                                                h-[40px]
                                                flex items-center gap-3 border ${itemType === inputType.OUTPUT ? 'border-[#B6BA3A]' : 'border-white'}`}
                                            onClick={() => setItemType(inputType.OUTPUT)}>
                                            {itemType === inputType.OUTPUT ? <img src={IconCirlceGreen} /> : <img src={IconCircleWhite} />} Inclusão por proprietário
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className='flex flex-col gap-6'>
                                    <div className="flex gap-6 font-main sm:flex-col ">
                                    <div className='flex gap-6 w-2/4 sm:flex-col w-[100%]'>
                                        <Input 
                                            label="Total de pontos"
                                            placeholder="Digite o total de pontos" 
                                            type="number"
                                        />
                                    </div>
                                    <div className='flex gap-6 w-2/4 sm:flex-col w-[100%] items-end'>
                                        <button
                                            className={`
                                                flex-[1_0_0]
                                                h-[40px]
                                                flex items-center gap-3 border ${itemType === inputType.ENTRY ? 'border-[#B6BA3A]' : 'border-white'}`}
                                            type='button'
                                            onClick={() => setItemType(inputType.ENTRY)}>
                                            {itemType === inputType.ENTRY ? <img src={IconCirlceGreen} /> : <img src={IconCircleWhite} />} Entrada
                                        </button>
        
                                        <button
                                            type='button'
                                            className={`
                                                flex-[1_0_0]
                                                h-[40px]
                                                flex items-center gap-3 border ${itemType === inputType.OUTPUT ? 'border-[#B6BA3A]' : 'border-white'}`}
                                            onClick={() => setItemType(inputType.OUTPUT)}>
                                            {itemType === inputType.OUTPUT ? <img src={IconCirlceGreen} /> : <img src={IconCircleWhite} />} Saída
                                        </button>
                                    </div>
                                    
                                    </div>
                              <div className='flex gap-6 w-2/4 sm:flex-col w-[100%]'>
                              <Input 
                                  label="Unidade"
                                  placeholder="Digite o número do apartamento" 
                                  type="number"
                              />
                              <Input 
                                  label="Título"
                                  placeholder="Digite o título do lançamento no extrato de pontos" 
                                  type="number"
                              />
                        
                            </div>

                      <div className="flex flex-col gap-4 rounded-lg">
                          <Input 
                              label="Detalhamento"
                              placeholder="Digite o detalhamento para o extrato de pontos" 
                              type="number"
                          />
                      </div>
                      </div>

                            )
                        }

                     
                      
              
                    </form>
                </div>

                
            </div>

            <div className="flex justify-between mt-auto shadow-custom p-10 sm:flex-col gap-6">
                <div>
                    <Button
                        title="Voltar"
                        onClick={() => navigate(-1)}
                        backGroundColor={"#FCFCFA"}
                        color={"#F28E00"}
                        borderRadius={8}
                    />
                </div>
                <div className='flex gap-4 sm:flex-col'>
                    <Button
                        title={isEdit ? 'Salvar benefício' : 'Cadastrar pontuação'}
                        backGroundColor={"#F28E00"}
                        color={"#FAFAFC"}
                        borderRadius={8}
                        type="submit"
                        form="my-form"
                    />
                </div>
            </div>
        </div>
    );
}
