import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Stack from '@mui/material/Stack'
import * as React from 'react'
import * as Styled from './styles'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

interface IActionsMenuProps {
  dataCy?: string
  onEdit?: (() => void) | null
  onDelete?: (() => void) | null
  onNote?: (() => void) | null
  onRenew?: (() => void) | null
  onView?: (() => void) | null
  onSuspense?: (() => void) | null
  onDownload?: (() => void) | null
}

export default function ActionsMenu({
  onEdit,
  onDelete,
  onRenew,
  dataCy,
  onNote,
  onView,
  onSuspense,
  onDownload
}: IActionsMenuProps) {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  const setEdit = () => {
    setOpen(false)
    if (onEdit) {
      onEdit()
    }
  }

  const setRenew = () => {
    setOpen(false)
    if (onRenew) {
      onRenew()
    }
  }

  const setDelete = () => {
    setOpen(false)
    if (onDelete) {
      onDelete()
    }
  }

  const setView = () => {
    setOpen(false)
    if (onView) {
      onView()
    }
  }

  const setSuspense = () => {
    setOpen(false)
    if (onSuspense) {
      onSuspense()
    }
  }

  const setNote = () => {
    setOpen(false)
    if (onNote) {
      onNote()
    }
  }

  const setDownload = () => {
    setOpen(false)
    if (onDownload) {
      onDownload()
    }
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus()
    }

    prevOpen.current = open
  }, [open])

  if (!onEdit && !onDelete && !onNote) {
    return null
  }

  return (
    <Stack direction="row" spacing={2}>
      <>
        <Styled.Menu
          ref={anchorRef}
          id="composition-button"
          {...(!!dataCy && {
            'data-cy': dataCy
          })}
          className="js-composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <MoreHorizIcon sx={{
            outline: 'none'
          }} />
        </Styled.Menu>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          placement="bottom-start"
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                    {!!onView && (
                      <MenuItem onClick={setView} className="js-view">
                        Visualizar relatórios
                      </MenuItem>
                    )}
                    {!!onEdit && (
                      <MenuItem onClick={setEdit} className="js-edit" sx={{
                        fontFamily: "Poppins"
                      }}>
                        Editar
                      </MenuItem>
                    )}
                    {!!onSuspense && (
                      <MenuItem onClick={setSuspense} className="js-suspend">
                        Suspender
                      </MenuItem>
                    )}
                    {!!onNote && (
                      <MenuItem onClick={setNote} className="js-note">
                        Adicionar Nota
                      </MenuItem>
                    )}
                    {!!onRenew && (
                      <MenuItem onClick={setRenew} className="js-renew">
                        Renovar
                      </MenuItem>
                    )}
                    {!!onDownload && (
                      <MenuItem onClick={setDownload} className="js-download">
                        Baixar relatório digitalizado (.pdf)
                      </MenuItem>
                    )}
                    {!!onDelete && (
                      <MenuItem
                        className="js-remove"
                        onClick={setDelete}
                        sx={{
                          color: 'red',
                          fontFamily: "Poppins"
                        }}
                      >
                        Excluir
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    </Stack>
  )
}
