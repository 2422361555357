import { Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { FaCheck } from 'react-icons/fa';
import * as Styled from './styles';
import { IUpload } from './types';

interface IUploadContractProps extends IUpload {
    error?: unknown;
    fileUrl?: string;
    disabled?: boolean;
}

const UploadContract: React.FC<IUploadContractProps> = ({ onUpload, hasFile, error, disabled, fileUrl }: IUploadContractProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [files, setFiles] = useState<File[]>([]);

    const extractTextAfterDynamicNumber = (url: string): string => {
        const regex = /(\d+)\/(.*)$/;
        const match = url.match(regex);
        return match ? match[2] : ''; 
    };

    const extractedText = fileUrl ? extractTextAfterDynamicNumber(fileUrl) : '';


    // const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const fileList = e.target.files;
    //     if (!fileList) return;

    //     const newFiles: File[] = [];

    //     // for (const file of fileList) {
    //     //     newFiles.push(file);  
    //     // }
        

    //     setFiles([...files, ...newFiles]);
    //     onUpload(newFiles); 
    // };
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files;
        if (!fileList) return;
    
        // Converte FileList em um array
        const newFiles: File[] = Array.from(fileList);
    
        setFiles([...files, ...newFiles]); // Atualiza o estado com os novos arquivos
        onUpload(newFiles); // Passa os novos arquivos para o callback onUpload
    };
    

 
    if (hasFile) {
        return (
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center', fontFamily: 'Poppins' }} className='font-main'>
            <Styled.PreviaContainer onClick={() => inputRef.current?.click()}>
                <Styled.PreviaDescription>
                    <input
                        type="file"
                        style={{ display: 'none', borderColor: error ? 'red' : 'inherit' }}
                        ref={inputRef}
                        onChange={handleFileChange}
                    />
                    <Typography variant="body2" color="#F28E00" className='font-main' sx={{
                        fontFamily: 'Poppins'
                    }}>
                        Escolher arquivo
                    </Typography>
                </Styled.PreviaDescription>
            </Styled.PreviaContainer>

        <Styled.FileUpload>
            <span>
                {files.length > 0 ? (
                    <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                        <FaCheck />
                        <Typography variant="body2">{files[0].name}</Typography>
                        <div>
                            <AiFillCloseCircle
                                color='#E86060'
                                onClick={() => {
                                    setFiles([]);
                                }}
                                data-cy="cancel-file-btn"
                            />
                        </div>
                    </div>
                ) : (
                    <Typography variant="body2" color="textSecondary">
                        {
                            fileUrl ? (
                                <p>{extractedText}</p>
                            ) : (
                               <p className='font-main' >Nenhum arquivo selecionado</p> 
                            )
                        }
                    </Typography>
                )}
            </span>
        </Styled.FileUpload>
    </div>
        );
    }

    return (
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <Styled.PreviaContainer onClick={() => inputRef.current?.click()}>
            <Styled.PreviaDescription>
            <Typography variant="body2" color="#F28E00" className='font-main' sx={{
                fontFamily: 'Poppins'}}>
                    Escolher arquivo
                </Typography>
                <input
                    disabled={disabled ? true : false}
                    type="file"
                    style={{ display: 'none' }}
                    ref={inputRef}
                    onChange={handleFileChange}
                />
             
            </Styled.PreviaDescription>
        </Styled.PreviaContainer>
        {
            error ?  (
                <Styled.FileUploadError>
                            <span>
                                {files.length > 0 ? (
                                    <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                                        <FaCheck />
                                        <Typography variant="body2">{files[0].name}</Typography>
                                        <div>
                                            <AiFillCloseCircle
                                                color='#E86060'
                                                onClick={() => {
                                                    setFiles([]);
                                                }}
                                                data-cy="cancel-file-btn"
                                            />
                                        </div>
                                    </div>
                                ) : (
                                        <Typography variant="body2" color="textSecondary" sx={{
                                            fontFamily: 'Poppins'
                                        }}>
                                            Nenhum arquivo selecionado
                                        </Typography>
                                )}
                            </span>
                </Styled.FileUploadError>
            ) : (
                <Styled.FileUpload>
                            <span>
                                {files.length > 0 ? (
                                    <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                                        <FaCheck />
                                        <Typography variant="body2">{files[0].name}</Typography>
                                        <div>
                                            <AiFillCloseCircle
                                                color='#E86060'
                                                onClick={() => {
                                                    setFiles([]);
                                                }}
                                                data-cy="cancel-file-btn"
                                            />
                                        </div>
                                    </div>
                                ) : (
                                        <Typography variant="body2" color="textSecondary" sx={{
                                            fontFamily: 'Poppins'
                                        }}>
                                            {
                                            
                                                fileUrl ? 'documento.pdf' : 'Nenhum arquivo selecionado'
                                            
                                            }
                                        </Typography>
                                )}
                            </span>
                </Styled.FileUpload>
            )
        }
        
    </div>
    );
};

export default UploadContract;
