import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ActionsMenu from '../../components/Table/ActionsMenu';

export function getColumns(
    toggleIsEdit?: ((row: undefined) => void) | null,
    deleteHotelChain?: ((row: undefined) => void) | null,
) {
    return [
        {
            name: 'Título',
            selector: (row: { name: string }) => (
                <span>{row.name}</span>
            ),
        },
        {
            name: 'Publicação',
            selector: (row: { category: string }) => (
                <span>{row.category}</span>
            ),
        },
        {
            name: 'Status',
            selector: (row: { category: number }) => (
                <FormGroup>
                    {
                        row.category === 1 ? (
                            <FormControlLabel control={<Switch defaultChecked />} label="Ativo " />
                        ) : (
                            <FormControlLabel control={<Switch defaultChecked />} label="Inativo " />
                        )
                    }
                </FormGroup>
            ),
        },
        
        {
            name: 'Ações',
            width: '60px',
            cell: (row: undefined) => (
                <ActionsMenu
                    onEdit={toggleIsEdit ? () => toggleIsEdit(row) : null}
                    onDelete={deleteHotelChain ? () => deleteHotelChain(row) : null}
                />
            ),
        },
    ];
}
